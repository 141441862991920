const canCaptureStackTrace = (typeof Error.captureStackTrace === 'function')

/**
 * Базовое исключение проекта
 */
export class RFRException extends Error {
  constructor(message) {
    super(message)
    this.name = this.constructor.name
    if (canCaptureStackTrace) {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = (new Error(message)).stack
    }
  }
}

/**
 * Недопустимый параметр функции
 */
export class IllegalArgumentException extends RFRException {

}


/**
 * Недопустим состояние вызова
 */
export class IllegalStateException extends RFRException {

}


/**
 * Внутренняя ошибка класса
 */
export class InvalidClassException extends RFRException {

}

export class AccessDeniedException extends RFRException {

}

export default {
  RFRException,
  InvalidClassException,
  IllegalStateException,
  IllegalArgumentException,
  AccessDeniedException,
}
