import { appContext } from 'common/RFR'


const IS_ADMIN = /^\/admin/.test(window.location.pathname)
appContext.application = IS_ADMIN ? 'admin' : 'user'

if (IS_ADMIN) {
  import('backoffice') // eslint-disable-line no-unused-expressions
} else {
  import('frontoffice') // eslint-disable-line no-unused-expressions
}
