import reduce from 'lodash/reduce'

import Exception from 'common/Exception'


class AppContext {
  // FIXME: такого быть не должно. но текущая архитектура приложения такова,
  // что appContext может быть расширен чем угодно где угодно
  // затем это используется где угодно, вместо использования публичного апи
  // для сохранения значений в регистри, все складируются прямо в инстанс класса
  [key: string]: any

  /** Реестр значений в контексте */
  private registry: { [key: string]: any } = {};

  /**
   * Регистрирует объект в контексте по ключу
   * @param key ключ объекта, если уже зарегистрирован, то будет сгенирирована ошибка
   * @param object регистриуруемый объект
   */
  public register(key: string, object: any) {
    if (this.registry[key]) {
      throw new Exception.IllegalArgumentException(`Повторная регистрация объекта с ключом "${key}" запрещена`)
    }
    this.registry[key] = object
  }

  /**
   * Получение значения из контекста. Может отсутствовать.
   */
  public lookup(key: string) {
    return this.registry[key]
  }

  /**
   * Выводит зарегистрированные в контексте значения
   */
  public print() {
    const registryString = reduce(this.registry, (result, _, key) => `${(result as string) + key} + \n`)
    console.log(`Зарегистрированы объекты: \n${registryString}`); // eslint-disable-line
  }
}

export const appContext = new AppContext()

export default {
  appContext,
}
